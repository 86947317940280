import * as React from "react";
import { App, Credentials } from "realm-web";
import type { User } from "realm-web";
import type { Services } from "realm";
import type { CarDocument, Collection } from "../../store/types";
const app: App = new App({ id: "triggers_realmapp-dtqzg" });

const Dealerships = ["brc-auto", "moller-auto"];
type Dealership = typeof Dealerships[number];

async function logIn(): Promise<User> {
    const credentials = Credentials.anonymous();
    return app.logIn(credentials);
}

const collection =
    <T extends Services.MongoDB.Document>(name: Dealership) =>
    (user: User): Collection<T> => {
        return user.mongoClient("Cluster0").db("cars").collection(name);
    };

const findAll = <T extends Services.MongoDB.Document>(
    col: Collection<T>,
): Promise<T[]> => {
    return col.find({}, { sort: { dateCreated: -1 } });
};

export const Table: React.FC = () => {
    const userRef = React.useRef<User | null>(null);
    const [cars, setCars] = React.useState<CarDocument[]>();

    React.useEffect(() => {
        logIn()
            .then((u) => {
                userRef.current = u;
                return u;
            })
            .then(collection<CarDocument>("cars"))
            .then((c) => findAll<CarDocument>(c))
            .then(setCars);

        return () => {
            userRef.current?.logOut();
        };
    }, []);

    return (
        <table>
            <thead>
                <tr>
                    <th>Url</th>
                    <th>Price</th>
                    <th>Hp</th>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Dealership</th>
                    <th>Transmission</th>
                </tr>
            </thead>
            <tbody>
                {cars?.map((car) => (
                    <Car key={car.url} car={car} />
                ))}
            </tbody>
        </table>
    );
};

const Car: React.FC<{ car: CarDocument }> = ({ car }) => {
    return (
        <tr>
            <td>
                <a href={car.url} target="_blank" rel="noreferrer">
                    {car.summary}
                </a>
            </td>
            <td>{car.price}</td>
            <td>{car.hp}</td>
            <td>{car.date}</td>
            <td>{car.type}</td>
            <td>{car.dealer}</td>
            <td>{car.transmission}</td>
        </tr>
    );
};
