import * as React from "react";
import cn from "classnames";
import { withBorder, background } from "./App.module.css";
import { Table } from "./Table";

export const App: React.FC = () => {
    return (
        <>
            <div className={cn(withBorder, background)}>
                Latest ads from dealerships
            </div>
            <Table />
        </>
    );
};
